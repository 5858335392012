import "./PrivacyInfoCarousel.scss";

import Carousel from "nuka-carousel";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ClickableElement from "../../../../../../../components/__common/_controls/Button/ClickableElement";
import {
  SVG_ICONS,
  Svg,
} from "../../../../../../../components/__common/Svg/Svg";
import { Typography } from "../../../../../../../components/__common/Typography/Typography";
import { Container } from "../../../../../../../components/Grid/Grid";
import { useWindowSize } from "../../../../../../../hooks/window-hooks";
import { ApplicationState } from "../../../../../../../store";
import { LayoutState } from "../../../../../../../store/reducers/layout-reducer";

const PrivacyInfoCarousel: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { isRTL } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const prevIconDisabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_LEFT_MOBILE_DISABLED
    : SVG_ICONS.CHEVRON_HUB_LEFT_DISABLED;

  const prevIconEnabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_LEFT_MOBILE
    : SVG_ICONS.CHEVRON_HUB_LEFT;

  const nextIconDisabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_RIGHT_MOBILE_DISABLED
    : SVG_ICONS.CHEVRON_HUB_RIGHT_DISABLED;

  const nextIconEnabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_RIGHT_MOBILE
    : SVG_ICONS.CHEVRON_HUB_RIGHT;

  //carousel custom left control
  const renderCenterLeftControls = ({ previousSlide, currentSlide }: any) => {
    return (
      <ClickableElement
        onClick={previousSlide}
        className="country-carousel__controls__button"
      >
        <Svg
          icon={currentSlide === 0 ? prevIconDisabled : prevIconEnabled}
          width={50}
          height={50}
        />
      </ClickableElement>
    );
  };

  //carousel custom right control
  const renderCenterRightControls = ({
    nextSlide,
    currentSlide,
    slideCount,
  }: any) => {
    const lastSlide = slideCount - 1;
    return (
      <ClickableElement
        onClick={nextSlide}
        className="country-carousel__controls__button"
      >
        <Svg
          icon={currentSlide === lastSlide ? nextIconDisabled : nextIconEnabled}
          width={50}
          height={50}
        />
      </ClickableElement>
    );
  };
  return (
    <div dir="ltr">
      <Carousel
        disableEdgeSwiping={true}
        className="privacy-carousel"
        defaultControlsConfig={{
          containerClassName: "privacy-carousel__controls",
          pagingDotsContainerClassName: "privacy-carousel__dots",
          pagingDotsClassName: "privacy-carousel__dots__dot",
        }}
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        slideIndex={isRTL ? 2 : 0}
      >
        {isRTL ? <NoAds /> : <SeeData />}
        <DeleteData />
        {isRTL ? <SeeData /> : <NoAds />}
      </Carousel>
    </div>
  );
};

export default PrivacyInfoCarousel;

const SeeData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container className="privacy-carousel__info download">
      <div className="text-container">
        <Typography variant="title2" className="privacy-carousel__info__title">
          {t("PRIVACY_HOME_BODY_INTRO_TITLE_TRANSPARENCY")}
        </Typography>
        <Typography
          variant="body1"
          weight="w500"
          className="privacy-carousel__info__details"
        >
          {t("PRIVACY_HOME_BODY_INTRO_EXP_TRANSPARENCY")}
        </Typography>
      </div>
    </Container>
  );
};

const DeleteData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container className="privacy-carousel__info delete">
      <div className="text-container">
        <Typography variant="title2" className="privacy-carousel__info__title">
          {t("PRIVACY_HOME_BODY_INTRO_TITLE_CHOICE")}
        </Typography>
        <Typography
          variant="body1"
          weight="w500"
          className="privacy-carousel__info__details"
        >
          {t("PRIVACY_HOME_BODY_INTRO_EXP_CHOICE")}
        </Typography>
      </div>
    </Container>
  );
};

const NoAds: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container className="privacy-carousel__info opt-out">
      <div className="text-container">
        <Typography variant="title2" className="privacy-carousel__info__title">
          {t("PRIVACY_HOME_BODY_INTRO_TITLE_SAFETY")}
        </Typography>
        <Typography
          variant="body1"
          weight="w500"
          className="privacy-carousel__info__details"
        >
          {t("PRIVACY_HOME_BODY_INTRO_EXP_SAFETY")}
        </Typography>
      </div>
    </Container>
  );
};
