import { useDispatch, useSelector } from "react-redux";

import {
  API_SA_BASE_URL,
  API_ENDPOINTS,
  API_BASE_URL,
  API_CHINA_SA_BASE_URL,
  API_SA_V3_BASE_URL,
} from "../constants/api";
import {
  ACR_KEY,
  ACR_VALUES,
  CLIENT_ID,
  DL_CLIENT_ID,
} from "../constants/appConstants";
import {
  COOKIE_SP_ATKN,
  EMAIL_LOGIN_VERIFICATION_RESULT,
  REDIRECT,
  REDIRECT_URI,
  RESTRICTED,
  USER_DATA_NAMESPACE,
  USER_SESSION_EXPIRED_TIME,
  USER_SESSION_ID,
} from "../constants/storage";
import { Cookie, LocalStorage } from "../services/storage";
import { actionLogout } from "../store/actions/user-actions";
import {
  SignInEntity,
  ConfirmPassEntity,
  SignInV2Entity,
  SignOutEntity,
} from "../types/auth";
import { generateToken } from "../utils/UtilsTokenGenerator";
import { REQUEST_TYPE } from "../types/user";
import { UtilsRequest } from "../utils/UtilsRequest";
import config from "../constants/serverConfig";
import { ROUTES } from "../configs/routes";
import { ApplicationState } from "../store";
import { LayoutState } from "../store/reducers/layout-reducer";
import {
  EVENT_CATEGORY_HEADER,
  EVENT_HEADER_ACTION_LOGIN,
  EVENT_HEADER_ACTION_LOGIN_CHINA,
  EVENT_HEADER_ACTION_LOGOUT,
} from "../constants/analytics";
import { ServiceEntity } from "../types/services";
import { getDomain } from "../utils/UtilsBrowser";
import GoogleAnalytics from "../utils/GoogleAnalytics";
import { UserState } from "../store/reducers/user-reducer";
import { GEOIP_CHINA_COUNTRY_CODE } from "../constants/locale";
import { useUserHooks } from "./user-hooks";
import { URLS } from "../constants/urls";
import { resetLanguage } from "../store/actions/layout-actions";

declare global {
  interface Window {
    samsung: {
      account: {
        signIn: (locale: SignInEntity, configs: { locale: string }) => void;
      };
    };
  }
}

export const useAuth = () => {
  const dispatch = useDispatch();
  const { isDigitalLegacy } = useUserHooks();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  /**
   * @deprecated
   * This function will be removed once V3 has been released by February 2025.
   * Please use `login` function instead.
   * It supports both Samsung Account and Digital Legacy login.
   */
  const loginV1 = (redirectURL?: string) => {
    const appState = generateToken(32);
    const SA_BASE_URL =
      userCountryCode === GEOIP_CHINA_COUNTRY_CODE
        ? API_CHINA_SA_BASE_URL
        : API_SA_BASE_URL;
    const EVENT_HEADER_LOGIN =
      userCountryCode === GEOIP_CHINA_COUNTRY_CODE
        ? EVENT_HEADER_ACTION_LOGIN_CHINA
        : EVENT_HEADER_ACTION_LOGIN;

    const authUrl = `${API_BASE_URL}${API_ENDPOINTS.AUTH_SIGNIN_URL}?returnUrl=${API_BASE_URL}${redirectURL}?success=1&appId=${CLIENT_ID}`;

    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);

    const paramsEntity: SignInV2Entity = {
      response_type: "code",
      client_id: CLIENT_ID,
      locale: layoutState.languageCode,
      countryCode: layoutState.countryCode,
      redirect_uri: authUrl,
      state: appState,
      goBackURL: API_BASE_URL,
      acr_values: ACR_VALUES,
      acr_key: ACR_KEY,
    };

    const params = new URLSearchParams({ ...paramsEntity });

    GoogleAnalytics.triggerEvent(EVENT_CATEGORY_HEADER, EVENT_HEADER_LOGIN);

    if (redirectURL === ROUTES.MYDATA) {
      window.location.replace(
        `${SA_BASE_URL}${API_ENDPOINTS.LOGIN_V1}?${params}`
      );
    } else {
      window.location.assign(
        `${SA_BASE_URL}${API_ENDPOINTS.LOGIN_V1}?${params}`
      );
    }
  };

  /**
   * @deprecated
   * This function will be removed once V3 has been released by February 2025.
   * Please use `login` function instead.
   * It supports both Samsung Account and Digital Legacy login.
   */
  const digitalLegacyLogin = (redirectURL?: string) => {
    const appState = generateToken(32);
    const authUrl = `${API_BASE_URL}${
      API_ENDPOINTS.AUTH_SIGNIN_URL
    }?returnUrl=${API_BASE_URL}${
      redirectURL || ROUTES.MYDATA
    }?success=1&appId=${DL_CLIENT_ID}`;

    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);

    const paramsEntity: SignInV2Entity = {
      response_type: "code",
      client_id: DL_CLIENT_ID,
      locale: layoutState.languageCode,
      countryCode: layoutState.countryCode,
      redirect_uri: authUrl,
      state: appState,
      goBackURL: API_BASE_URL,
      acr_values: ACR_VALUES,
      acr_key: ACR_KEY,
    };

    const params = new URLSearchParams({ ...paramsEntity });

    window.location.replace(
      `${API_SA_V3_BASE_URL}${API_ENDPOINTS.LOGIN}?${params}`
    );
  };

  const login = (redirectURL?: string, isDigitalLegacy: boolean = false) => {
    // cleanup the persisted data in redux to ensure that the profile, and GEOIP API will be called after successfull login
    // note: this will just reset the redux state to it's default value
    dispatch(actionLogout());
    const appState = generateToken(32);
    const APP_ID = isDigitalLegacy ? DL_CLIENT_ID : CLIENT_ID;
    const authUrl = `${API_BASE_URL}${API_ENDPOINTS.AUTH_SIGNIN_URL}?returnUrl=${API_BASE_URL}${redirectURL}?success=1&appId=${APP_ID}`;

    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);
    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_HEADER,
      EVENT_HEADER_ACTION_LOGIN
    );

    const paramsEntity: SignInEntity = {
      responseType: "code",
      clientId: APP_ID,
      redirectUri: encodeURIComponent(authUrl),
      state: appState,
      scope: "",
    };

    window.samsung.account.signIn(
      { ...paramsEntity },
      { locale: `${layoutState.languageCode}-${layoutState.countryCode}` }
    );
  };

  const logout = async (isIdle?: boolean) => {
    dispatch(resetLanguage());
    const appState = generateToken(32);
    const authUrl = `${API_BASE_URL}${API_ENDPOINTS.AUTH_SIGNOUT_URL}?returnUrl=${API_BASE_URL}/`;

    LocalStorage.remove(USER_SESSION_ID);
    sessionStorage.removeItem(RESTRICTED);
    sessionStorage.removeItem(REDIRECT);
    Cookie.remove(REDIRECT_URI);

    const paramsEntity: SignOutEntity = {
      client_id: CLIENT_ID,
      signOutURL: authUrl,
      state: appState,
      acr_values: ACR_VALUES,
    };

    dispatch(actionLogout());
    LocalStorage.remove(USER_DATA_NAMESPACE);
    LocalStorage.remove(USER_SESSION_EXPIRED_TIME);
    LocalStorage.remove(EMAIL_LOGIN_VERIFICATION_RESULT);

    const params = new URLSearchParams({ ...paramsEntity });

    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_HEADER,
      EVENT_HEADER_ACTION_LOGOUT
    );

    if (isDigitalLegacy) {
      const hostname = window.location.hostname;
      const domain = getDomain(hostname);
      Cookie.remove(COOKIE_SP_ATKN, {
        path: "/",
        domain: domain,
      });
      if (userCountryCode === GEOIP_CHINA_COUNTRY_CODE) {
        return window.location.replace(URLS.CHINA_LOCALSITE);
      }
      if (isIdle) {
        window.history.replaceState(null, "", ROUTES.ROOT);
        localStorage.setItem("SHOW_TIMEOUT_DIALOG", "true");
      }
      return () => {};
    }

    if (!isIdle) {
      window.location.replace(
        `${API_SA_BASE_URL}${API_ENDPOINTS.LOGOUT}?${params}`
      );
    }
    if (isIdle) {
      window.history.replaceState(null, "", ROUTES.ROOT);
      localStorage.setItem("SHOW_TIMEOUT_DIALOG", "true");
    }
    const domain = getDomain(window.location.hostname);
    return Cookie.remove(COOKIE_SP_ATKN, {
      path: "/",
      domain: domain,
    });
  };

  const confirmPassword = (
    services: Array<ServiceEntity>,
    requestType: REQUEST_TYPE
  ) => {
    const arrServices: string[] = [];

    services.forEach((service) => {
      arrServices.push(service.service_code);
    });

    UtilsRequest.setRequestInfo(arrServices, requestType);
    const appState = generateToken(32);

    const redirectConf = `${API_BASE_URL}${ROUTES.MYDATA_HISTORY}`;
    const goBackUrl = `${config.APP_BASE_URL_NO_PARAMS}${ROUTES.MYDATA}`;
    const paramsEntity: ConfirmPassEntity = {
      locale: layoutState.languageCode,
      countryCode: layoutState.countryCode,
      redirect_uri: redirectConf,
      state: appState,
      goBackURL: goBackUrl,
    };

    const params = new URLSearchParams({ ...paramsEntity });

    window.location.replace(
      `${API_SA_BASE_URL}${API_ENDPOINTS.CONFIRM_PASS_URL}?${params}`
    );
  };

  return {
    loginV1,
    digitalLegacyLogin,
    login,
    logout,
    confirmPassword,
  };
};
