import { COMMON_NON_AUTH_ROUTES, ROUTES } from "../configs/routes";
import { REDIRECT, REDIRECT_URI, REQUEST_NUMBER } from "../constants/storage";
import { Cookie } from "../services/storage";

/**
 * Get the URL where the user is located prior to logging in.
 *
 * This function also handles the redirect URL wherein the user clicks a link
 * in a request email (opt out, deletion, and download).
 */
export const getRedirectUrl = () => {
  const redirectUrlSession = sessionStorage.getItem(REDIRECT);
  const currentPathname = window.location.pathname;

  /**
   * IF no REDIRECT session:
   * EX. IF current URL is privacy.samsung.com/privacy/samsung -> RETURN /privacy/samsung as login redirect URL to redirect to current page upon succesful login
   * EX. IF current URL is ROOT -> RETURN "/" -> redirect to ROOT page after succesful login
   */
  if (!redirectUrlSession) {
    return isCommonNonAuthRoute(currentPathname)
      ? currentPathname
      : ROUTES.ROOT;
  }

  let redirect = removeSuccessQueryParam(redirectUrlSession);

  /**
   * IF REDIRECT URL session has valid request number:
   * EX. /my-data/SP-12345ASD
   * SPLITTED_REDIRECT_URL = ["my-data","SP-12345ASD"]
   * SPLITTED_REDIRECT_URL.pop() -> SPLITTED_REDIRECT_URL = ["my-data"] // use pop() method to remove reqnum as LOGIN only accepts whitelisted REDIRECT_URI like /my-data or /my-data/history
   * SPLITTED_REDIRECT_URL.join() -> redirect = "my-data"
   * THEN SET REDIRECT_URI cookie -> "my-data/reqnum" // this cookie is use to store the path where to redirect after succesful login.
   */
  if (hasValidRequestNumber(redirect)) {
    const pathWithoutRequestNumber =
      removeRequestNumberFromPath(redirectUrlSession);
    redirect = pathWithoutRequestNumber;
    Cookie.set(REDIRECT_URI, `${redirect}/${Cookie.get(REQUEST_NUMBER)}`);
  }

  return redirect;
};

// Helper function to check if a path is in common non-auth routes like overview or privacy policy path.
const isCommonNonAuthRoute = (pathname: string) =>
  COMMON_NON_AUTH_ROUTES.includes(pathname);

// Helper function to remove the '?success=1' query parameter from a URL
const removeSuccessQueryParam = (url: string) =>
  url.replaceAll("?success=1", "");

// Helper function to check if a URL has a valid request number (e.g., SP-12345ASD) in its path. Returns true if it does, false otherwise.
const hasValidRequestNumber = (url: string) =>
  url.match(/SP|SPDL-(DEV|STG|[0-9A-Z]*)/g);

// Helper function to remove the request number from a URL path
const removeRequestNumberFromPath = (url: string) => {
  const splittedUrl = url.split("/");
  splittedUrl.pop();
  return splittedUrl.join("/");
};
