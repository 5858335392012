import React, { useEffect } from "react";

interface Props {
  /** The child elements inside the modal */
  children: React.ReactNode;
  /** A selector string for focusable elements inside the modal */
  elements: string;
  /** Determines if focus trapping is active */
  isActive: boolean;
  /** Callback function triggered when the Escape key is pressed */
  onHandleClose?: () => void;
}
/**
 * FocusTrapping
 *
 * This component traps focus inside a modal-like container, ensuring that tabbing cycles only through
 * focusable elements within the modal. It also listens for the Escape key to trigger a close action.
 *
 * Example Usage:
 * ```tsx
 * <FocusTrapping elements="button, input, [tabindex]" isActive={true} onHandleClose={() => console.log('Modal closed')}>
 *   <div>Modal Content</div>
 * </FocusTrapping>
 * ```
 */
const FocusTrapping: React.FC<Props> = (props) => {
  const modalRef = React.useRef(null);
  const { children, elements, isActive, onHandleClose } = props;

  useEffect(() => {
    if (isActive) {
      const modalElement: React.LegacyRef<HTMLDivElement> = modalRef;
      const focusableElements =
        modalElement.current && modalElement.current.querySelectorAll(elements);
      const arrayOfElements =
        focusableElements && Array.from(focusableElements);
      const firstElement = focusableElements && focusableElements[0];
      const lastElement =
        focusableElements && focusableElements[focusableElements.length - 1];
      if (firstElement) {
        (firstElement as HTMLElement).setAttribute("tabindex", "0");
        (firstElement as HTMLElement).classList.add("firstChild");
        (firstElement as HTMLElement).focus();
      }

      const lastRegion = modalElement.current?.querySelector("#last-region");

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape" && onHandleClose) {
          event.preventDefault();
          onHandleClose();
        }
        if (
          event.key === "Tab" &&
          modalElement &&
          arrayOfElements &&
          document.activeElement
        ) {
          const index = arrayOfElements.indexOf(document.activeElement);

          // specific case for LocalPrivacySiteModal
          const lastClass =
            lastRegion?.classList[lastRegion?.classList.length - 1];
          const isLastRegion =
            document.activeElement.firstChild ===
            lastRegion?.querySelector("div");
          if (lastClass !== "active" && isLastRegion) {
            event.preventDefault();
            (firstElement as HTMLElement).setAttribute("tabindex", "0");
            (firstElement as HTMLElement).focus();
            (firstElement as HTMLElement).classList.remove("firstChild");
          }
          if (
            index === -1 ||
            ((lastElement as HTMLElement).hasAttribute("disabled") &&
              index === arrayOfElements.length - 2)
          ) {
            event.preventDefault();
            (firstElement as HTMLElement).setAttribute("tabindex", "0");
            (firstElement as HTMLElement).focus();
            (firstElement as HTMLElement).classList.remove("firstChild");
          }

          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            (lastElement as HTMLElement).setAttribute("tabindex", "0");
            (lastElement as HTMLElement).focus();
            (lastElement as HTMLElement).classList.remove("firstChild");
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            (firstElement as HTMLElement).setAttribute("tabindex", "0");
            (firstElement as HTMLElement).focus();
            (firstElement as HTMLElement).classList.remove("firstChild");
          }
        }
      };

      modalElement.current &&
        modalElement.current.addEventListener("keydown", handleKeyDown);

      return () => {
        modalElement.current &&
          modalElement.current.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [modalRef, children, isActive]);

  return <div ref={modalRef}>{children}</div>;
};

export default FocusTrapping;
