import React from "react";
import Modal from "../../../../components/__common/Modal/Modal";
import { Typography } from "../../../../components/__common/Typography/Typography";
import "./PrivacyRightsModal.scss";
import { Button } from "../../../../components/__common/_controls";
import Carousel, { ControlProps } from "nuka-carousel";
import { useTranslation } from "react-i18next";
import Rights from "./Rights";
import { RegionPrivacyRights } from "../../../../data/landing-page";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { LayoutState } from "../../../../store/reducers/layout-reducer";
import { SVG_ICONS, Svg } from "../../../../components/__common/Svg/Svg";
import classNames from "classnames";
import { ERegions, MapRegion } from "../../../../types/landing-page";
import ClickableElement from "../../../../components/__common/_controls/Button/ClickableElement";

interface Props {
  show: boolean;
  onClickClose: () => void;
  country: MapRegion;
}

const PrivacyRightsModal: React.FC<Props> = ({
  show,
  onClickClose,
  country,
}) => {
  const { t } = useTranslation();
  const { isRTL } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const renderPrivacyRights = () => {
    if (isRTL) {
      const reversed = RegionPrivacyRights[country.rightsCode].rights.reverse();

      return reversed.map((right: any) => (
        <Rights key={right} type={right} region={country.rightsCode} />
      ));
    }

    return RegionPrivacyRights[country.rightsCode].rights.map((right: any) => (
      <Rights key={right} type={right} region={country.rightsCode} />
    ));
  };

  const renderCenterLeftControls = ({
    previousSlide,
    currentSlide,
  }: ControlProps) => {
    const buttonClass = classNames(
      "rights-carousel__controls__button",
      "rights-carousel__controls__button--left",
      { disabled: currentSlide === 0 }
    );

    return (
      <ClickableElement onClick={previousSlide} className={buttonClass}>
        <Svg icon={SVG_ICONS.CHEVRON_RIGHT} width={30} height={30} />
      </ClickableElement>
    );
  };

  const renderCenterRightControls = ({
    nextSlide,
    currentSlide,
    slideCount,
  }: ControlProps) => {
    const buttonClass = classNames(
      "rights-carousel__controls__button",
      "rights-carousel__controls__button--right",
      { disabled: currentSlide === slideCount - 1 }
    );

    return (
      <ClickableElement className={buttonClass} onClick={nextSlide}>
        <Svg icon={SVG_ICONS.CHEVRON_RIGHT} width={30} height={30} />
      </ClickableElement>
    );
  };

  return (
    <div className="privacy-rights-modal">
      <Modal show={show}>
        <Modal.Header onHide={onClickClose}>
          <Modal.Title>
            {t("PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="privacy-rights-modal__message">
            <Typography variant="body2" component="p">
              {t("PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_DESC1")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_DESC2")}
            </Typography>
          </div>
          <Typography
            variant="title3"
            className="privacy-rights-modal__heading"
          >
            {country.code !== ERegions.MENA &&
              country.code !== ERegions.ASIA_PACIFIC &&
              t(`PRIVCY_HUB_MODAL_PRIVACY_RIGHTS_${country.rightsCode}`)}

            {/* SAPP and MENA has no DID so title is hardcoded */}
            {country.code === ERegions.MENA && "MENA Privacy Rights"}
            {country.code === ERegions.ASIA_PACIFIC &&
              "S.E Asia & Oceania Privacy Rights"}
          </Typography>
          <Svg
            className="privacy-rights-modal__country"
            icon={SVG_ICONS[`HUB_RIGHTS_MAP_${country.rightsCode}`]}
          />
          <div dir="ltr">
            <Carousel
              className="rights-carousel"
              defaultControlsConfig={{
                containerClassName: "rights-carousel__controls",
                pagingDotsContainerClassName: "rights-carousel__dots",
              }}
              slideIndex={
                isRTL
                  ? RegionPrivacyRights[country.rightsCode].rights.length - 1
                  : 0
              }
              renderCenterLeftControls={renderCenterLeftControls}
              renderCenterRightControls={renderCenterRightControls}
            >
              {renderPrivacyRights()}
            </Carousel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            title={t("PRIVACY_MYDATA_USAGE_GUIDE_BTN")}
            onClick={onClickClose}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PrivacyRightsModal;
