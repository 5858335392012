import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Svg, SVG_ICONS } from "../../components/__common/Svg/Svg";
import { ROUTES_CONSIGNMENT_PAGE } from "../../configs/layout";
import { ROUTES } from "../../configs/routes";
import {
  EVENT_ACTION_MANAGE_COOKIES,
  EVENT_CATEGORY_FOOTER,
  EVENT_FOOTER_ACTION_COOKIE_POLICY,
  EVENT_FOOTER_ACTION_PRIVACY_POLICY,
} from "../../constants/analytics";
import { IS_PRE, IS_PROD } from "../../constants/environment";
import { useCookie } from "../../hooks/cookie-hooks";
import { useLanguage } from "../../hooks/language-hooks";
import { useLayout } from "../../hooks/layout-hooks";
import { useWindowSize } from "../../hooks/window-hooks";
import { ApplicationState } from "../../store";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { SystemState } from "../../store/reducers/site-reducer";
import { UserState } from "../../store/reducers/user-reducer";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { UtilsDateTime } from "../../utils/UtilsDateTIme";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import FocusTrapping from "../__hoc/FocusTrapping";
import { ManageCookieModal } from "../CookieModal/ManageCookie";
import { Container } from "../Grid/Grid";
import { LanguageModal } from "../LanguageModal/LanguageModal";
import "./Footer.scss";
import { FooterMobile } from "./FooterMobile";
import ClickableElement from "../__common/_controls/Button/ClickableElement";

export const Footer: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { setManageCookieModalOpen } = useCookie();
  const { isLanguageModalShow, setIsLanguageModalShow } = useLanguage();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const { country, language, isManageCookieOpen } = layoutState;

  useEffect(() => {
    if (isLanguageModalShow) {
      document.getElementById("language-modal-tts")?.focus();
    }
  }, [isLanguageModalShow]);

  return (
    <>
      {isManageCookieOpen && (
        <FocusTrapping
          isActive={isManageCookieOpen}
          onHandleClose={() => setManageCookieModalOpen(false)}
          elements="svg, button, div.title2 , div.modal-body, div.manage-cookie"
        >
          <ManageCookieModal
            isOpen={isManageCookieOpen}
            onHide={() => setManageCookieModalOpen(false)}
          />
        </FocusTrapping>
      )}
      <FocusTrapping
        isActive={isLanguageModalShow}
        onHandleClose={() => setIsLanguageModalShow(false)}
        elements="div.modal-body, div.title2, svg, button, input"
      >
        <LanguageModal
          headertestid="language-modal-hide-btn"
          isSiteLanguage
          show={isLanguageModalShow}
          onHide={() => setIsLanguageModalShow(false)}
        />
      </FocusTrapping>
      {!isMobile ? (
        <FooterDesktop
          onClickLanguage={() => setIsLanguageModalShow(true)}
          onClickManageCookie={() => setManageCookieModalOpen(true)}
          country={country}
          language={language}
        />
      ) : (
        <FooterMobile
          testId="footer-mobile"
          onClickLanguage={() => setIsLanguageModalShow(true)}
          onClickManageCookie={() => setManageCookieModalOpen(true)}
          country={country}
          language={language}
        />
      )}
    </>
  );
};

interface DesktopProps {
  onClickLanguage: () => void;
  onClickManageCookie: () => void;
  country?: string;
  language?: string;
}

export const FooterDesktop: React.FC<DesktopProps> = (props) => {
  const { t } = useTranslation();
  const { setTheme } = useLayout();
  const { pathname } = useLocation();
  const [isVersionShowing, setVersionShowing] = useState<boolean>(true);
  const shouldShowVersion = !IS_PROD && !IS_PRE && isVersionShowing;
  const {
    sysProps: { BUILD_TIMESTAMP, BUILD_VERSION },
  } = useSelector<ApplicationState, SystemState>((state) => state.system);

  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { userRegion } = userState;
  const handleLanguageClick = () => {
    props.onClickLanguage();
  };

  const handleManageCookieClick = () => {
    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_FOOTER,
      EVENT_ACTION_MANAGE_COOKIES
    );
    props.onClickManageCookie();
  };

  return (
    <>
      <div
        className={classNames("footer", {
          "snap-scroll": pathname === ROUTES.ROOT,
          "consignment-footer": ROUTES_CONSIGNMENT_PAGE.includes(pathname),
        })}
      >
        <Container className="footer__content">
          <div className="footer__content__left">
            <div>
              {!routeShouldRenderComponent(
                pathname,
                ROUTES_CONSIGNMENT_PAGE
              ) && (
                <div className="footer__content__left__links">
                  <Link
                    data-testid="link-to-privacy-policy"
                    className="footer__link sharp-sans"
                    to={ROUTES.PRIVACY_POLICY}
                    onClick={() =>
                      GoogleAnalytics.triggerEvent(
                        EVENT_CATEGORY_FOOTER,
                        EVENT_FOOTER_ACTION_PRIVACY_POLICY
                      )
                    }
                  >
                    {t("PRIVACY_HEADER_MENU_PRIVACY_POLICY")}
                  </Link>
                  {userRegion.isFromEurope && (
                    <>
                      <span className="divider"></span>
                      <Link
                        data-testid="link-to-cookie-policy"
                        className="footer__link sharp-sans"
                        to={ROUTES.COOKIE_POLICY}
                        onClick={() =>
                          GoogleAnalytics.triggerEvent(
                            EVENT_CATEGORY_FOOTER,
                            EVENT_FOOTER_ACTION_COOKIE_POLICY
                          )
                        }
                      >
                        {t("PRIVACY_FOOTER_MENU_COOKIES")}
                      </Link>

                      <span className="divider"></span>
                      {/* eslint-disable-next-line */}
                      <a
                        className="footer__link sharp-sans"
                        data-testid="handle-cookie-button"
                        onClick={() => handleManageCookieClick()}
                        href="#"
                      >
                        {t("PRIVACY_FOOTER_MENU_MANAGE_COOKIES")}
                      </a>
                    </>
                  )}

                  <span className="divider"></span>
                  <button
                    data-testid="handle-language-button"
                    className="footer__link sharp-sans"
                    onClick={handleLanguageClick}
                  >
                    <span className="country">{props.country}</span>
                    &nbsp;/&nbsp;
                    <span>{props.language}</span>
                    <Svg
                      className="arrow-right"
                      width={16}
                      height={16}
                      icon={SVG_ICONS.ARROW_RIGHT}
                    />
                  </button>
                </div>
              )}

              <div className="footer__content__left__copyright sharp-sans">
                <span>
                  Copyright © Samsung Electronics Co, Ltd. All Rights Reserved
                </span>
                <div className="footer__content__left__links__themes">
                  <button
                    data-testid="hc-toggle-button-on"
                    className="icon-link"
                    onClick={() => setTheme(true)}
                  >
                    <Svg
                      width={16}
                      height={16}
                      icon={SVG_ICONS.FOOTER_THEME_HIGH_CONTRAST}
                    />
                  </button>
                  <button
                    data-testid="hc-toggle-button-off"
                    className={classNames("icon-link", "icon-link-last")}
                    onClick={() => setTheme(false)}
                  >
                    <Svg
                      width={16}
                      height={16}
                      icon={SVG_ICONS.FOOTER_THEME_DEFAULT}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {shouldShowVersion && (
            <div
              className={classNames("footer__content__version", {
                hc: isHighContrast,
              })}
            >
              <div className="footer__content__version__left">
                <div
                  className={classNames(
                    "footer__content__version__left__flex",
                    {
                      hc: isHighContrast,
                    }
                  )}
                >
                  <div
                    className={classNames(
                      "footer__content__version__left__flex__badge",
                      {
                        hc: isHighContrast,
                      }
                    )}
                  >
                    FE
                  </div>
                  <p>
                    v.{process.env.REACT_APP_ENVIRONMENT}.
                    {process.env.REACT_APP_VERSION}
                    <p className="fade">
                      -
                      {UtilsDateTime.formatDate(
                        process.env.REACT_APP_TIMESTAMP!
                      )}
                    </p>
                  </p>
                </div>
                <div
                  className={classNames(
                    "footer__content__version__left__flex",
                    {
                      hc: isHighContrast,
                    }
                  )}
                >
                  <div
                    className={classNames(
                      "footer__content__version__left__flex__badge",
                      {
                        hc: isHighContrast,
                      }
                    )}
                  >
                    BE
                  </div>
                  <p>
                    {BUILD_VERSION}
                    <p className="fade">
                      -{UtilsDateTime.formatDate(BUILD_TIMESTAMP)}
                    </p>
                  </p>
                </div>
              </div>
              <ClickableElement
                className="footer__content__version__right"
                onClick={() => setVersionShowing(false)}
              >
                <Svg
                  width={14}
                  height={14}
                  icon={
                    isHighContrast
                      ? SVG_ICONS.FOOTER_VERSION_CLOSE_ICON_HC
                      : SVG_ICONS.FOOTER_VERSION_CLOSE_ICON
                  }
                />
              </ClickableElement>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};
