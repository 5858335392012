import React from "react";
import classnames from "classnames";
import "./ClickableElement.scss";

interface ClickableElementProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The child element to wrap */
  children: React.ReactNode;
}

/**
 * ClickableElement
 *
 * A custom component used for any element that is clickable but not a button,
 * such as icons, SVGs, and images. It can be wrapped around these elements to retain keyboard
 * shortcuts like tabs and enter, making them focusable and activatable via keyboard navigation.
 *
 * Usage:
 * ```tsx
 * <ClickableElement onClick={() => console.log('Clicked!')}>
 *   <img src="image.png" alt="Image" />
 * </ClickableElement>
 * ```
 */
const ClickableElement: React.FC<ClickableElementProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button className={classnames("hidden-button", className)} {...props}>
      {children}
    </button>
  );
};

export default ClickableElement;
