import React, { ReactNode, useMemo } from "react";
import { EmailSupportContext } from "./EmailSupportContext";

type EmailSupportProps = {
  children: ReactNode;
  supportedCountries: string[];
};

export const EmailSupport = ({
  children,
  supportedCountries,
}: EmailSupportProps) => {
  const contextValue = useMemo(
    () => ({ supportedCountries }),
    [supportedCountries]
  );

  return (
    <EmailSupportContext.Provider value={contextValue}>
      {children}
    </EmailSupportContext.Provider>
  );
};
