import { useContext } from "react";
import { EmailSupportContext } from "./EmailSupportContext";

export const useEmailSupport = () => {
  const { supportedCountries } = useContext(EmailSupportContext);

  const isEmailLoginSupported = (countryCode: string): boolean => {
    return supportedCountries.includes(countryCode);
  };

  return { isEmailLoginSupported };
};
