import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import { ROUTES } from "../../configs/routes";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Overlay } from "../__common/Overlay/Overlay";
import { SubNav } from "./SubNav";
import { Container } from "../Grid/Grid";
import { HeaderMenu } from "../../constants/nav";
import { useWindowSize } from "../../hooks/window-hooks";
import { HeaderMobile } from "./HeaderMobile";
import { Image } from "../__common/Image";
import { Typography } from "../__common/Typography/Typography";
import { LinkButton } from "../__common/_controls";
import { useUserHooks } from "../../hooks/user-hooks";
import { LOGIN_ID_TYPE, User } from "../../types/user";
import { UtilsUser } from "../../utils/UtilsUser";
import { useAuth } from "../../hooks/auth-hooks";
import { API_ENDPOINTS, API_SA_BASE_URL } from "../../constants/api";
import {
  EVENT_CATEGORY_HEADER,
  EVENT_HEADER_ACTION_PRIVACY_LOGO,
} from "../../constants/analytics";
import { useHub } from "../../hooks/hub-hooks";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import { useOTP } from "../../hooks/otp-hooks";
import {
  EMAIL_LOGIN_VERIFICATION_RESULT,
  REDIRECT,
} from "../../constants/storage";
import IdleTimer from "../../utils/IdleTimer";
import { LocalStorage } from "../../services/storage";
import { URLS } from "../../constants/urls";
import {
  DISABLED_FEATURES,
  useFeatureToggle,
} from "../../utils/feature-toggle/useFeatureToggle";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import { LayoutState } from "../../store/reducers/layout-reducer";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { TURKEY_SA_COUNTRY_CODE } from "../../constants/countryDefaults";
import ClickableElement from "../__common/_controls/Button/ClickableElement";

export const Header: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { getUserSurveyResponse, userData } = useUserHooks();
  const { logout } = useAuth();
  const { isDisabled } = useFeatureToggle();
  const isGetSurveyResponseHasLoaded = useRef<boolean>(false);
  const emailLoginVerificationResult = LocalStorage.get(
    EMAIL_LOGIN_VERIFICATION_RESULT
  );
  useEffect(() => {
    if (UtilsUser.isUserLoggedIn()) {
      if (
        !isDisabled(DISABLED_FEATURES.SURVEY) &&
        !isGetSurveyResponseHasLoaded.current
      ) {
        isGetSurveyResponseHasLoaded.current = true;
        getUserSurveyResponse();
      }

      const timer = new IdleTimer({
        timeout: 600, //10 minutes on idle
        onTimeOut: () => logout(true),

        onExpired: () => logout(true),
      });

      return () => {
        timer.cleanup();
      };
    }
    return () => {};
  }, [userData, emailLoginVerificationResult]);

  return !isMobile ? <HeaderDesktop /> : <HeaderMobile />;
};

export const HeaderDesktop: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { pathname } = location;
  const { handleLoginBasedOnGeoIp } = useLoginOptionHooks();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userData, isDigitalLegacy } = useUserHooks();
  const { isHighContrast } = layoutState;
  const { hasLocalSite, handleLocalSiteRedirect, handleGAMyDataRedirectNav } =
    useHub();

  const saCountry = userData.countryCode;
  // get the active menu based on the path name
  const getActiveMenu = (name: string) => {
    switch (name) {
      case "PRIVACY_HEADER_MENU_PRIVACY_POLICY":
        if (
          pathname === URLS.POLICY_OVERVIEW ||
          pathname === URLS.PRIVACY_POLICY
        )
          return true;
        return false;

      case "PRIVACY_HEADER_MENU_MYDATA":
        if (pathname.includes(URLS.MYDATA)) return true;
        return false;
    }
  };

  const handleNavOnClick = (id: string | number, gaAction: string) => {
    setIsProfileOpen(false);

    if (gaAction !== "") {
      if (id === 2) {
        sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
        handleGAMyDataRedirectNav();
        handleLoginBasedOnGeoIp();
      } else {
        GoogleAnalytics.triggerEvent(EVENT_CATEGORY_HEADER, gaAction);
      }
    }
  };

  const handleNavOnMouseLeave = () => {
    setIsProfileOpen(false);
  };

  const handleOnProfileClick = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    setIsProfileOpen(false);
  }, [location]);

  return (
    <>
      <Overlay
        testId="overlay-container"
        open={showSubMenu || isProfileOpen}
        onClick={handleOnProfileClick}
      />

      <div className="header-container">
        <header
          className={classNames("header", {
            underlined: pathname !== ROUTES.ROOT,
          })}
          id="header"
          tabIndex={-1}
        >
          <Container className="header__content">
            <div className="header__logo">
              <div className="header__logo__container">
                <Link
                  data-testid="link-to-root"
                  to={ROUTES.ROOT}
                  onClick={() =>
                    handleNavOnClick("", EVENT_HEADER_ACTION_PRIVACY_LOGO)
                  }
                >
                  <Svg
                    icon={
                      !isHighContrast
                        ? SVG_ICONS.LOGO_DEFAULT
                        : SVG_ICONS.LOGO_HC
                    }
                    width={198}
                    height={"auto"}
                  />
                </Link>
              </div>
            </div>
            <div className="header__nav">
              {HeaderMenu.map((menu) => (
                <div
                  data-testid="profile-container"
                  key={menu.id}
                  className={classNames("nav-item", {
                    active: getActiveMenu(menu.title.text),
                  })}
                  onMouseLeave={menu.items && handleNavOnMouseLeave}
                >
                  {menu.title.link !== "" ? (
                    (menu.id === 2 && hasLocalSite && !isDigitalLegacy) ||
                    saCountry === TURKEY_SA_COUNTRY_CODE ? (
                      <Typography
                        testId="handle-mydata-redirection-button"
                        className={classNames("nav-item-text", {
                          active: getActiveMenu(menu.title.text),
                        })}
                        onClick={() => {
                          handleGAMyDataRedirectNav();
                          handleLocalSiteRedirect();
                        }}
                        variant="body2"
                      >
                        <Link to="#">{t(menu.title.text)}</Link>
                      </Typography>
                    ) : (
                      <Typography
                        className={classNames("nav-item-text", {
                          active: getActiveMenu(menu.title.text),
                        })}
                        testId="privacy-policy-menu-button"
                        onClick={() => handleNavOnClick(menu.id, menu.gaAction)}
                        variant="body2"
                      >
                        <Link to={menu.title.link}>{t(menu.title.text)}</Link>
                      </Typography>
                    )
                  ) : (
                    <Typography
                      testId="hide-sub-menu"
                      className={classNames("nav-item-text", {
                        active: getActiveMenu(menu.title.text),
                      })}
                      onClick={() => {
                        setShowSubMenu(!showSubMenu);
                      }}
                      variant="body2"
                    >
                      <Link to="#">{t(menu.title.text)}</Link>
                    </Typography>
                  )}
                  {menu.items && (
                    <SubNav
                      isActive={showSubMenu}
                      navigation={menu}
                      setShowSubMenu={setShowSubMenu}
                    />
                  )}
                </div>
              ))}

              <Profile
                userData={userData}
                onProfileClick={handleOnProfileClick}
                isProfileOpen={isProfileOpen}
              />
            </div>
          </Container>
        </header>
      </div>
    </>
  );
};

interface ProfileProps {
  userData: User;
  isProfileOpen?: boolean;
  onProfileClick?: () => void;
}

const Profile: React.FC<ProfileProps> = ({
  userData,
  isProfileOpen,
  onProfileClick,
}) => {
  // Third-party hooks
  const { t } = useTranslation();

  // Custom hooks
  const { logout } = useAuth();
  const { handleLoginBasedOnGeoIp } = useLoginOptionHooks();
  const { isVerifying } = useOTP();
  const { isDigitalLegacy } = useUserHooks();
  // Variables
  const emailLoginVerificationResult = LocalStorage.get(
    EMAIL_LOGIN_VERIFICATION_RESULT
  );
  const isEmailOrDLLogin =
    emailLoginVerificationResult === "true" || isDigitalLegacy;

  return (
    <div className="profile">
      {!UtilsUser.isUserLoggedIn() && !isVerifying && (
        <ClickableElement onClick={handleLoginBasedOnGeoIp}>
          <Svg icon={SVG_ICONS.PROFILE_SIGNOUT} width={40} height={40} />
        </ClickableElement>
      )}

      {UtilsUser.isUserLoggedIn() && !isVerifying && (
        <>
          <button onClick={onProfileClick}>
            {userData.profileImageUrl ? (
              <Image
                image={userData.profileImageUrl}
                name={userData.name}
                width={40}
                height={40}
              />
            ) : (
              <Svg
                icon={SVG_ICONS.PROFILE}
                className="profile-placeholder"
                width={40}
                height={40}
              />
            )}
          </button>
          <div
            className={classNames(
              `profile__popup ${
                isEmailOrDLLogin ? "cursor-default" : "cursor-pointer"
              }`,
              {
                open: isProfileOpen,
              }
            )}
          >
            <Link
              data-testid="sa-profile-link"
              target="_blank"
              to={{
                pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
              }}
              className={classNames({
                "block-user-event": isEmailOrDLLogin,
              })}
            >
              <div className="profile__popup__user">
                <div className="profile__popup__user__icon">
                  {userData.profileImageUrl ? (
                    <Image
                      image={userData.profileImageUrl}
                      name={userData.name}
                      width={48}
                      height={48}
                    />
                  ) : (
                    <Svg
                      icon={SVG_ICONS.PROFILE}
                      className="profile-placeholder"
                      width={48}
                      height={48}
                    />
                  )}
                </div>
                <div>
                  <Typography
                    className="profile__popup__user__name"
                    variant="title3"
                  >
                    {userData.name}
                  </Typography>
                </div>
              </div>
            </Link>

            <div className="profile__popup__message cursor-default">
              <Typography variant="body1">
                {t("PRIVACY_HEADER_USER_POPUP_GREETING")} <br />{" "}
                {t("PRIVACY_HEADER_USER_POPUP_GREETING_TEXT")}
              </Typography>
            </div>
            <div
              className={`${
                isEmailOrDLLogin ? "cursor-default" : "cursor-pointer"
              }`}
            >
              <LinkButton
                data-testid="sa-profile-link"
                title={
                  userData.loginIdType === LOGIN_ID_TYPE.EMAIL
                    ? userData.validatedEmail
                    : userData.loginId
                }
                to={{
                  pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
                }}
                target="_blank"
                underlined={false}
                icon={!UtilsUser.isEmailLogin() && SVG_ICONS.GREATE_THAN_SML}
                iconSize={24}
                className={classNames({
                  "block-user-event": isEmailOrDLLogin,
                })}
                textClassName="profile__popup__email"
              />
            </div>
            <div className="horizontal-divider" />
            <div>
              <LinkButton
                testId="logout-button"
                textClassName="signout"
                title={t("PRIVACY_HEADER_SIGNOUT")}
                to={ROUTES.ROOT}
                underlined={false}
                onClick={() => {
                  onProfileClick && onProfileClick();
                  logout();
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
